import { parse } from 'query-string';
import React, { useEffect } from 'react';
import { getSiteUrl } from 'helpers/routingHelper';

type PaymentErrorProps = {
  location: Location;
};

const PaymentError: React.FC<PaymentErrorProps> = ({ location }) => {
  const params = parse(location.search);

  useEffect(() => {
    window.parent.postMessage(
      {
        type: 'error',
      },
      getSiteUrl() || ''
    );
  }, [params]);

  return null;
};

export default PaymentError;
